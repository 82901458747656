/*
    CUSTOM CSS FOR WALLET
*/
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.amountRange::-webkit-slider-runnable-track {
    background: #7b7b7bc4;
    height: 7.5px;
}

.amountRange::-webkit-slider-thumb {
    width: 15px;
    height: 15px;
}

.radio-select-custom {
    opacity: 0.3;
}
.radio-select .form-check-input:checked + .form-check-label .radio-select-custom {
    border: 1px solid #2c7be5 !important;
    opacity: 1;
    cursor: default;
}

.radio-select .form-check-input:hover {
    cursor: pointer;
}