/*
    CUSTOM CSS FOR ACCELERATOR BAR in BOT DETAILS
*/
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.coin-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
