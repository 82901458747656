/*
    CUSTOM CSS FOR Coming Soon
*/

.margin-top-custom {
  box-shadow: none !important;
}

@media screen and (max-width: 576px) {
  .margin-top-custom {
      margin-top: 40%;
  }
  .svg-custom svg{
    width: 120% !important;
    transform: translate3d(-20%, 0px, 0px)!important;
  }
}

