/*
    CUSTOM CSS FOR BotState IN BotDetails
*/
.binance-button {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: rgba(255, 217, 0, 1) !important;
  cursor: pointer !important;
}

.binance-button:hover {
  background-color: rgba(255, 217, 0, 0.25) !important;
}
