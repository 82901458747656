/*
    CUSTOM CSS FOR bot settings AND CHILDREN
*/
.pointerCursor {
    cursor: pointer !important;
}

.scaleOnHover {
    overflow: hidden;
}

.scaleOnHover:hover  img {
    transform: scale(1.3);
}

.imgCardGroup {
    overflow: hidden;
}

.overImg:hover{
    transform: scale(1.3);
}

.h5Card:hover {
    color:darkgray; 
}