/*
    CUSTOM CSS FOR FORM
*/

.hover-bg-custom-100:hover{
    background-color: var(--falcon-100) !important;
}
.hover-bg-custom-200:hover{
    background-color: var(--falcon-200) !important;
}
.hover-bg-custom-300:hover{
    background-color: var(--falcon-300) !important;
}
.hover-bgImg-0:hover {
    background-image: linear-gradient(0deg,#012552,#001835)!important;

}
.hover-bg-custom:hover{
    background-color: #3440503d!important;
}