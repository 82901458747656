/*
    CUSTOM CSS FOR LinePayment AND CHILDREN
*/


.pointer-cursor-chart div div {
    cursor: pointer !important;
  }

.pointer-default-chart div div {
    cursor: default !important;
}