/*
    CUSTOM CSS FOR TABLES
*/


/* Extra small devices (portrait phones, less than 576px) */
/* No media query since this is the default in Bootstrap */

.d-table-group-header {
    display: table-header-group !important;
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .d-sm-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .d-md-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .d-lg-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .d-xl-table-group-header {
      display: table-header-group !important;
    }
  }


// ADD extra-class to override the default bootstrap classes
.extra-class.d-table-group-header {
    display: table-header-group !important;
  }
  
  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    .extra-class.d-sm-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) {
    .extra-class.d-md-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .extra-class.d-lg-table-group-header {
      display: table-header-group !important;
    }
  }
  
  /* Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    .extra-class.d-xl-table-group-header {
      display: table-header-group !important;
    }
  }

  .border-bottom-table {
    border-bottom: 1px solid #232e3c;
  }