/* ButtonGrid.css */

.button-container {
  display: flex; 
  justify-content: space-between; 
  width: min-content;
}

.square-button {
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default !important;
    transition: transform 0.2s;
    text-shadow: 0 0 4px #00000080;
  }

.button-size-sm {
    width: 1.5rem;
    height: 1.5rem;
}
.button-size-md {
  width: 2.5rem;
  height: 2.5rem;
}

.button-size-string {
  white-space: nowrap;
}

.square-button-click {
    cursor: pointer !important;
}

.square-button-hover:hover {
  transform: scale(1.1); 
}

.square-button-selected {
  transform: scale(1.1);
}
