/*
    CUSTOM CSS FOR CoinStats AND CHILDREN
*/
/* Definisci classe per il bordo primario */
// .hover-border-primary {
//     border: 1px solid var(--falcon-card-bg);
//     cursor: pointer;
//   }
  
  .hover-border-primary:hover {
    border: 1px solid var(--falcon-primary); 
    cursor: pointer;
  }