/*
Custom Wizard for Bot Details
*/

// First Child
.theme-wizard .nav-item:first-child .nav-item-circle-parent-custom:before {
    width: 0 !important;
}


// Last Child
.theme-wizard .nav-item:last-child .nav-item-circle-parent-custom:before {
    width: 105% !important;
    transform: translate3d(-55%, -50%, 0) !important;
}
.theme-wizard .nav-item:last-child .active .nav-item-circle-parent-custom:after {
    width: 105% !important;
    transform: translate3d(-55%, -50%, 0) !important;
    background-color: #2c7be5 !important;
}


// Others
.theme-wizard .nav-link .nav-item-circle-parent-custom:before {
    transform: translate3d(-100%, -50%, 0) !important;
}
.theme-wizard .nav-item:not(:first-child):not(:last-child) .active .nav-item-circle-parent-custom:after {
    width: 101% !important;
    transform: translate3d(-50%, -50%, 0) !important;
    background-color: #2c7be5 !important;
}