
.hover-card {
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    padding: 10px; 
    border-radius: 8px;
  }
  

  .hover-card:hover {
    background-color: #4d5969;  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;  
  }

  .hover-alert-danger {
    transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .hover-alert-danger:hover {
    background-color: rgb(97 42 53 / 60%);  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;  
    border-color: rgb(97 42 53 / 60%) !important;
  }  

  .hover-card .flex-1 h6 {
    transition: color 0.3s ease-in-out;
  }
  