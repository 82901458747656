/*
    CUSTOM CSS FOR MAIN
*/
#main{
    padding-left: calc(100vw - 100%);
}


.tab-content {
    padding-top: 3rem!important;
}


// SENTIMENT
.tooltip-text {
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    transition: opacity 0.3s;
  }


//  .btn-close {
//   background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414 1.707 15.707a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50% / 1em auto no-repeat !important;
//   opacity: 1;
// }

.w-fit-content {
    width: fit-content !important;
}
