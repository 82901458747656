/*
    CUSTOM CSS FOR CoinStats AND CHILDREN
*/
.mt-info {
    margin-top: .15rem !important;
}


.default-cursor div div {
    cursor: default !important;
  }