/*
    CUSTOM CSS FOR BotInfo IN DASHBOARD
*/
.z-index-1 {
    z-index: 100 !important;
}

.mw-fit-content {
    max-width: fit-content !important;
}

.dim-6 {
    width: 0.7rem !important;
    height: 0.7rem !important;
}


.background-border{
    margin-top: 1.3rem;
    border-style: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 10px;
    background-color: white
}


@media screen and (min-width: 576px) {
    .hover-bg-custom-row-100:hover{
        background-color: var(--falcon-100) !important;
        cursor: pointer;
    }

    .hover-bg-custom-row-200:hover{
        background-color: var(--falcon-200) !important;
        cursor: pointer;
    }

    .hover-bg-custom-row-300:hover{
        background-color: var(--falcon-300) !important;
        cursor: pointer;
    }
} 

.outline-warning-custom{
    color: #FAC70F !important;
    border-color: #FAC70F !important;
}

.outline-warning-custom:hover{
    background-color: #FAC70F !important;
    border-color: #FAC70F !important;
    color: black !important;
}

.custom-padding{
    padding-left: 0.25rem !important;
    padding-right: 0.23rem !important;
}

.custom-dim{
    height: .9rem !important;
    width: .9rem !important;
}