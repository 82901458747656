/*
    CUSTOM CSS FOR MAIN LAYOUT
*/
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 574px) {
    .center-page{
        padding-top: 70%;
    }
}
@media only screen and (min-width: 575px) and (max-width: 1349px) {
    .center-page{
        padding-top: 35%;
    }
}
@media only screen and (min-width: 1350px) {
    .center-page{
        padding-top: 20%;
    }
}


@media only screen and (min-width: 1200px) and (max-width: 1539px) {
    .max-width-container{
        max-width: 1250px !important;
    }
}

@keyframes fade-out {
    to {
      opacity: 0;
      visibility: hidden;
    }
  }