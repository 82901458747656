/*
    CUSTOM CSS FOR Cards in Real Mode
*/
// .realCard {
//     background-color: rgba(216, 226, 239, 0.05) !important;
//     border: solid rgba(250, 203, 16, 0.35) !important;
//     border-width: 0.1em !important;
// }

// .virtualCard {
//     //background-color: rgba(216, 226, 239, 0.05) !important;
//     border: solid rgba(216, 226, 239, 0.5) !important;
//     border-width: 0em !important;
// }

.realCard {
    background-image: var(--falcon-line-chart-gradient);
}

.virtualCard {
    //background-color: rgba(216, 226, 239, 0.05) !important;
    border: solid rgba(216, 226, 239, 0.5) !important;
    border-width: 0em !important;
}