/*
    CUSTOM CSS FOR FOOTER
*/

@media screen and (max-width: 576px) {
    .footer-custom {
        // position: relative !important;
        // padding-bottom: 3rem !important;
        // margin-top: 3rem !important;
        // bottom: unset !important;
        bottom: 45px !important;
    }
  }